import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"
import { Layout, Row, Col } from "antd"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const { Footer } = Layout

const GFooter = ({ menuOpen }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logoFooter.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  const intl = useIntl()

  return (
    <Footer className={menuOpen ? "mobile-layout-hidden" : ""}>
      <div>
        <Row type="flex" justify="space-between" align="top" gutter={24}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Img
              fluid={data.logo.childImageSharp.fluid}
              alt="Elevage du cèdre Enchanté"
            />
            <p style={{ textAlign: "justify" }}>
              {intl.formatMessage({ id: "FOOTER.DESCRIPTION" })}
            </p>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <h2>{intl.formatMessage({ id: "FOOTER.TITLE_ABOUT" })}</h2>
            <ul>
              <li>
                <Link to="/elevage">
                  {intl.formatMessage({ id: "FOOTER.LINK_ELEVAGE" })}
                </Link>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://archives.cedrenchante.com/"
                >
                  {intl.formatMessage({ id: "FOOTER.LINK_ARCHIVES" })}
                </a>
              </li>
              <li>{intl.formatMessage({ id: "FOOTER.LINK_PARTNERS" })}</li>
            </ul>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <h2>{intl.formatMessage({ id: "FOOTER.TITLE_CONTACT" })}</h2>
            <ul>
              <li>
                <Link to="/contact">
                  {intl.formatMessage({ id: "FOOTER.LINK_CONTACT" })}
                </Link>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/cedrenchante/"
                >
                  {intl.formatMessage({ id: "FOOTER.LINK_FACEBOOK" })}
                </a>
              </li>
              <li>Livre d'or</li>
            </ul>
          </Col>
        </Row>
      </div>
      <div>
        {intl.formatMessage(
          { id: "FOOTER.COPYRIGHT" },
          {
            year: new Date().getFullYear(),
            name: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://youribondu.fr/"
              >
                Youri Bondu
              </a>
            ),
            date: new Date().toLocaleDateString("fr-FR"),
          }
        )}
        {/* Cedrenchante ©{new Date().getFullYear()} Created by <a target="_blank" rel="noopener noreferrer" href="https://youribondu.fr/">Youri Bondu</a> - Mise à jour le {new Date().toLocaleDateString("fr-FR")} */}
      </div>
    </Footer>
  )
}

export default GFooter
