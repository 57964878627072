import PropTypes from "prop-types"
import React, { useContext } from "react"
import Menu from './menu'
import { Layout } from 'antd'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import MenuContext from './menuContext'
import Burger from './burger';

const { Header } = Layout;

const GHeader = ({ page }) => {
  const contextValue = useContext(MenuContext)

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Header
      style={{
        'position': 'fixed',
        'zIndex': '100',
        'width': '100%',
        'backgroundColor': 'white',
        'borderBottom': '1px solid #e8e8e8',
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          width: '100%',
          maxWidth: 1150,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Img fluid={data.logo.childImageSharp.fluid} style={{ width:"200px" }} alt="Logo Le Cèdre Enchanté"/>
        <Burger open={contextValue.open} setOpen={contextValue.openMenu} />
        <Menu page={page}/>
      </div>
    </Header>
  )
}

GHeader.propTypes = {
  siteTitle: PropTypes.string,
}

GHeader.defaultProps = {
  siteTitle: ``,
}

export default GHeader
