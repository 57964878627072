import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'gatsby'
import { useIntl } from "gatsby-plugin-intl"

const GBreadcrumb = ({ titles }) => {
    const intl = useIntl()

    return (
        <div style={{ backgroundColor: "#f7f5f2", padding: "30px 10vw", width: "100%", borderBottom: "1px solid #eeedea" }}>
            <div style={{
                margin: `0 auto`,
                width: '100%',
                maxWidth: 1150,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <h3 style={{ margin: '0', padding: '0', float:'left', textTransform: "uppercase" }}>{intl.formatMessage({ id: `BREADCRUMB.${titles[titles.length - 1 ].toUpperCase()}` })}</h3>
                <Breadcrumb separator=">" style={{ textAlign: "right" }}>
                    {titles.map((title, i) => (
                        i === titles.length-1 ?
                        <Breadcrumb.Item key={i}>{intl.formatMessage({ id: `BREADCRUMB.${title.toUpperCase()}` })}</Breadcrumb.Item> :
                        <Breadcrumb.Item key={i}><Link to={title === 'HOME' ? '' : `/${title.toLowerCase()}`}>{intl.formatMessage({ id: `BREADCRUMB.${title.toUpperCase()}` })}</Link></Breadcrumb.Item>
                    ))}
                </Breadcrumb>
            </div>
        </div>
    );
};

export default GBreadcrumb;