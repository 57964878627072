import React from 'react';
// import Language from './language'
import { useIntl, Link } from "gatsby-plugin-intl"
import adopt from '../images/icon-adopt-pet.svg'
import { Menu } from 'antd';
const { SubMenu } = Menu;

const GMenu = ({ page }) => {
  const intl = useIntl()

  return (
    <Menu
        className="menu"
        mode="horizontal"
        direction="rtl"
        defaultSelectedKeys={[page]}
    >
        <Menu.Item key="home">
            <Link to="/">
                {intl.formatMessage({ id: "MENU.HOME" })}
            </Link> 
        </Menu.Item>

        <SubMenu key="sub0" title={<span>{intl.formatMessage({ id: "MENU.ELEVAGE" })}</span>}>
            <Menu.Item key="elevage">
                <Link to="/elevage">
                    {intl.formatMessage({ id: "MENU.ELEVAGE" })}
                </Link> 
            </Menu.Item>
        </SubMenu>
        <Menu.Item key="blog">
            <Link to="/blog">
                {intl.formatMessage({ id: "MENU.BLOG" })}
            </Link> 
        </Menu.Item>
        <SubMenu key="sub1" title={<span>{intl.formatMessage({ id: "MENU.DOGS" })}</span>}>
            <Menu.Item key="chiots" className="menu_link">
                <Link to="/chiots">
                    {intl.formatMessage({ id: "MENU.PUPPIES" })}
                </Link> 
            </Menu.Item>
            <Menu.Item key="berger-des-shetland">
                <Link to="/berger-des-shetland">
                {intl.formatMessage({ id: "MENU.DOG_SHETLAND" })}
                </Link> 
            </Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" title={<span>{intl.formatMessage({ id: "MENU.CATS" })}</span>}>
            <Menu.Item key="chatons" className="menu_link">
                <Link to="/chatons">
                    {intl.formatMessage({ id: "MENU.KITTENS" })}
                </Link> 
            </Menu.Item>
            <Menu.Item key="british-shorthair">
                <Link to="/british-shorthair">
                {intl.formatMessage({ id: "MENU.CAT_BRITISH" })}
                </Link> 
            </Menu.Item>
        </SubMenu>
        <Menu.Item key="contact">
            <Link to="/contact">
                {intl.formatMessage({ id: "MENU.CONTACT" })}
            </Link> 
        </Menu.Item>
        <Menu.Item id="adopt-button" key="adopt" >
            <Link to="/adopt">
                <img id="icon-adopt" src={adopt} alt="icon adoption chiot chatons" style={{maxHeight:"50px", width:"auto", paddingTop:"5px"}}/>
                <span style={{lineHeight:'initial', textAlign:"center"}}>{intl.formatMessage({ id: "MENU.ADOPT" })}</span>
            </Link> 
        </Menu.Item>
    </Menu>
  )
}

export default GMenu;