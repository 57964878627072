/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Breadcrumb from "../components/breadcrumb";
import Header from "./header"
import Footer from "./footer"
import "../theme/index.scss"
import Img from "gatsby-image"
import MenuContext from './menuContext'
import MenuVerticalComponent from './menuVertical';

import { Layout } from 'antd'

const { Content } = Layout;

const GLayout = ({ children, titles, page, bgColorDark , homeHeader}) => {
  const [open, setOpen] = useState(false);
  const contextValue = {
    open,
    openMenu: setOpen
  }
  return (
    <MenuContext.Provider value={contextValue}>
      <Layout>
        <Header page={page} />
        <div style={open ? {display: "block" }: { display: "none"}}>
          <MenuVerticalComponent page={page} />
        </div>
        <div className={(bgColorDark ? "layout layout_dark " : "layout layout_light ") + (open ? ' mobile-layout-hidden ': '')}>
          {titles && <Breadcrumb titles={titles}/>}
          {homeHeader && <Img fluid={homeHeader} style={{ width:"100%" }} alt="Elevage du Cèdre Enchanté"/>}
          <Content className="layout_content">
            <div>{children}</div>
          </Content>
        </div>
        <Footer menuOpen={open}/>
      </Layout>
    </MenuContext.Provider>
  )
}

GLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GLayout